import React, { Component } from 'react'

class Duo extends Component {
	render() {

		let data = this.props.data

		return (
			<div className="content duo">
				<div className="center main">
					<div>
						<div className="text1">
							<div className="center">
								<div className="rich" dangerouslySetInnerHTML={{__html: data ? data.text1 : null}} />
							</div>
						</div>
						<div className="table">
							<div className="col">
								<span className="image" style={{backgroundImage: `url(${data ? data.person1image : null})`}}></span>
								<h3>Christian Mattick</h3>
								<h4>Flöte</h4>
								<div className="rich" dangerouslySetInnerHTML={{__html: data ? data.person1text : null}} />
							</div>
							<div className="col separator"></div>
							<div className="col">
								<span className="image" style={{backgroundImage: `url(${data ? data.person2image : null})`}}></span>
								<h3>Mathias Huth</h3>
								<h4>Piano</h4>
								<div className="rich" dangerouslySetInnerHTML={{__html: data ? data.person2text : null}} />
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Duo;