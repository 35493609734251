import React, { Component } from 'react'

import Draggable from 'react-draggable' 

import Play from '../../../media/play.svg'
import Pause from '../../../media/pause.svg'

class AudioPlayer extends Component {
	constructor(props) {
    	super(props);

    	this.player = React.createRef();
        this.position = React.createRef();

    	this.state = {
            isPlaying: false,
            isDragging: false,
            isDraggedMin: false,
            width: 0,
            x: 0
        }  

    	this.url = this.props.data.file
    	
  	}

  	componentDidMount() {
        if(this.props.data.file) {
            window.addEventListener('resize', this.setSizes)
            this.setSizes();
        }
  	}

    setSizes = () => {
        let rect = this.player.current.getBoundingClientRect();
        let rect2 = this.position.current.getBoundingClientRect();
        
        this.setState({
            width: rect.width-rect2.width
        })
    }

  	togglePlay = () => {
  		if(this.state.isDraggedMin) {
  			return
  		}

  		if(!this.state.isPlaying) {

  			if(!this.audio) {
  				this.audio = new Audio(this.url);
  			}

            this.audio.onended = () => {
                this.togglePlay();
                this.audio.currentTime = 0;

                this.setState({
                    x: 0
                })

            };

            this.props.onPlay(this.props.data.id);

  			this.audio.play()

  			this.interval = setInterval(
	  			this.setPosition
  			, 100);

  		} else {
  			this.audio.pause()

  			clearInterval(this.interval);
  		}

  		this.setState({
            isPlaying: !this.state.isPlaying
        })
  	}

  	onDragStart = () => {
  		this.setState({
            isDragging: true
        })
  	}

  	onDrag = (e, ui) => {
  		this.setState({
  			isDraggedMin: this.state.isDraggedMin ? true : Math.abs(ui.deltaX) > 3 ? true : false,
            x: this.state.x + ui.deltaX
        })
  	}

  	onDragStop = () => {
        clearTimeout(this.timeout);

        if(this.state.isDraggedMin) {
        	let position = this.state.x/this.state.width * this.audio.duration

        	this.audio.currentTime = position
        }

    	this.timeout = setTimeout(() => {
            this.setState({
            	isDraggedMin: false,
	            isDragging: false
	        })
        }
        , 50);	
  	}

  	setPosition = () => {
  		if(this.state.isDragging) {
  			return false
  		}

  		let position = this.audio.currentTime/this.audio.duration * this.state.width
  		
  		this.setState({
            x: position
        })
  	}

    componentWillUpdate(nextProps, nextState) {
        if(nextProps.playingID !== this.props.data.id && this.state.isPlaying) {
            this.togglePlay();
        }

    }

	render() {
		let icon = this.state.isPlaying ? Pause : Play
		
		return (
			<div className="player audio">
				{this.props.data.file ? (
                    <div className="track">
    					<span ref={this.player}>
                            <Draggable
        						position={{x: this.state.x, y:0}}
        						axis="x"
        						bounds={{left: 0, right: this.state.width}}
        						onStart={this.audio ? this.onDragStart : null}
        						onDrag={this.audio ? this.onDrag : null}
        						onStop={this.audio ? this.onDragStop : null}
        					>
        						<div className="position" onClick={this.togglePlay} ref={this.position}>
                                    <img src={icon} alt={this.state.isPlaying ? 'Pause' : 'Play'} />
        						</div>
        					</Draggable>
                        </span>
    				</div>
                ): (
                     <div className="track empty" />
                )
                } 
				<p>{this.props.data.text}</p>
			</div>
		)
	}
}

export default AudioPlayer;