import React, { Component } from 'react'

class Icon extends Component {
    getIcon() {

    	switch(this.props.type) {
    		case 'arrow':
    			return (
    				<svg viewBox="0 0 28 53">
  
                        <polyline points="1.5 1.5 26.5 26.5 1.5 51.5" style={{fill: 'none',stroke: '#be7878', strokeLinecap:'round', strokeLinejoin: 'round', strokeWidth: '3px'}} />
    
                    </svg>
                )
            default:
                return
        }
    }

    render() {
        return (
            this.getIcon()
        )
    }
 }

 export default Icon;