const UrlTransform = (url, type) => {
	//return type === 'add' ? '/preview' + url : url.replace("/preview/", "/")
	return url
}

const FormatBytes = (bytes) => {
    if(bytes < 1024) return bytes + " Bytes";
    else if(bytes < 1048576) return(bytes / 1024).toFixed(1) + " KB";
    else if(bytes < 1073741824) return(bytes / 1048576).toFixed(1) + " MB";
    else return(bytes / 1073741824).toFixed(1) + " GB";
}

export { 
	UrlTransform,
	FormatBytes
}