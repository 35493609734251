import Reflux from 'reflux'
import axios from 'axios'
import ContentStoreActions from '../actions/ContentStoreActions'

import { UrlTransform } from '../Helper'

class ContentStore extends Reflux.Store {
    constructor() {
     	super()
     	this.listenables = [ContentStoreActions]
     	this.state = {
			content: [],
		};

		this.url = 'http://localhost/milchhof/duo-mattick/deploy/wordpress/loader.php'
        this.url = 'https://duo-mattick-huth.de/wordpress/loader.php'
    }

    onGetContent(url) {

        url = UrlTransform(url)

    	let data = {
	      	params: {
	        	url: url,
                scale: window.devicePixelRatio && window.devicePixelRatio > 1.25 ? 2 : 1
	      	}
	    }

    	axios.get(this.url, data, {headers: {}})
        .then(response => {            
        	let content = this.state.content
        	content[encodeURIComponent(url)] = response.data

        	this.setState({
                content: content
            })
        })
        .catch(function (error) {
            console.log(error)
        });

    }

    onImageLoaded(id) {
        let loadedImages = this.state.loadedImages
        loadedImages.push(id)

        this.setState({
            loadedImages: loadedImages
        })
    }
}

ContentStore.id = 'ContentStore'

export default ContentStore