import React, { Component } from 'react'

import { FormatBytes } from '../../../Helper'

class Program extends Component {
	render() {	

		let data = this.props.data

		let programs = data.programs.map((entry, idx) => { 

			let composer = `${entry.composer} `

    		if(entry.lifetime) {
    			composer += `<span>(${entry.lifetime})</span>`
    		}

            return (
            	<li key={idx}>
            		{(idx===0 && !data.file) &&
            			<div className="separator"><span><span /><span /></span></div>
            		}

            		<span className="composer" dangerouslySetInnerHTML={{__html: composer}} />
            		<h5 dangerouslySetInnerHTML={{__html: entry.title}} />
            		{entry.info &&
            			<p dangerouslySetInnerHTML={{__html: entry.info}} />
            		}
            		{entry.pause ? (
				        <div className="separator pause"><span><span /><span />(Pause)</span></div>
				    ) : (
				        <div className="separator"><span><span /><span /></span></div>
				    )}

            	</li>
            )
        });

		return (
			<div className="program">
				<h3 dangerouslySetInnerHTML={{__html: data.title}} />
				<h4 dangerouslySetInnerHTML={{__html: data.subTitle}} />
				<div className="rich" dangerouslySetInnerHTML={{__html: data.text}} />
				{data.file &&
					<div className="download">
						<p>Download Programm-Informationen:</p>
						<a href={data.file} className="file pdf" download><span>{data.description}</span> <span className="meta">(PDF, {FormatBytes(data.filesize)})</span></a>
					</div>
				}
				<ul>
					{programs}
				</ul>
			</div>
		)
	}
}

export default Program;