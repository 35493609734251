import React from 'react'
import PropTypes from 'prop-types'
import Reflux from 'reflux'
import { withRouter } from 'react-router-dom'
import {Transition} from 'react-transition-group'

import ContentStore from '../../../stores/ContentStore'
import ContentStoreActions from '../../../actions/ContentStoreActions'

import Program from './Program'
import Dates from '../Dates'

import { UrlTransform } from '../../../Helper'



class Overlay extends Reflux.Component {
	static contextTypes = {
        router: PropTypes.object
    }

	constructor(props) {
        super(props)

        this.stores = [ContentStore]

        this.url = ''

        this.ref = React.createRef()
    }

	close = () => {
		
		if(!this.entered) {
			return false 
		}

		this.entered = false;

		this.setState({
            in: false
        })
		
		setTimeout(() => {
            
 			//this.props.history.goBack()
        	this.props.history.replace(UrlTransform( '/' + this.props.match.params.level1, 'add'))
        }
        , 380);  
	}

	updateData(props, state) {
		
 		if(this.url !== this.props.url && this.props.in && this.props.match.params.level2) {
 			this.url = this.props.url
 			ContentStoreActions.getContent(this.url)

 			this.setState({
                in: true
            })
 			window.scrollTo(0, 0)
 		}
 	}

	componentDidMount() {
		
		this.updateData(this.props, this.state)

		window.addEventListener('resize', this.setSize)
 	}

 	componentWillUpdate(nextProps, nextState) {
 		this.updateData(nextProps, nextState)
 	}


 	componentDidUpdate() {
 		this.setSize();
 	}

 	setSize = () => {
 		if(!this.state.in) {
 			return
 		}

 		let rect = this.ref.current.getBoundingClientRect()

 		this.props.setMinHeight(rect.height);
 	}

	render() {
		let data = this.url ? this.state.content[encodeURIComponent(UrlTransform(this.url))] : null

		let content

		if(this.url.indexOf('programme') !== -1) {
			content = <Program data={data} style={this.props.style} />
		} else if(this.url.indexOf('termine') !== -1) {
			content = <Dates data={data ? data.content : [] } position="overlay" pathName={this.url} style={this.props.style} />
		}

		const transitionStyles = {
		    entering: { transform: `translateX(100%)` },
		    entered: { transform:  `translateX(30px)` },
		    exiting: { transform: `translateX(100%)` }
		};

		const transitionStyles2 = {
		    entering: { opacity: 0 },
		    entered: { opacity:  0.4 },
		    exiting: { opacity: 0 }
		};

		//console.log(this.in)

		return (
			<Transition in={this.state.in} timeout={{ enter: 400, exit: 400}} mountOnEnter={true} unmountOnExit={true} appear onEntered={()=>{ this.entered = true }} onExited={()=>{ this.url = '' }}>
	            {(state) => (
					<div className="overlay" >
						<Transition in={this.state.in} timeout={{ enter: 0, exit: 400}} mountOnEnter={true} unmountOnExit={true} appear>
			                {(state) => (
								<div className="content" style={{...transitionStyles[state]}} ref={this.ref}>
									
									<div>
										{data &&
											content
										}
										{!data &&
											<span className="loading">Laden</span>
										}
									</div>
								</div>
							 )}
			            </Transition>
			            <Transition in={this.state.in} timeout={{ enter: 0, exit: 400}} mountOnEnter={true} unmountOnExit={true} appear>
			                {(state) => (
			                	<span className="close" onClick={this.close} style={{...transitionStyles[state]}}>
									<span>
										<span />
										<span />
									</span>
								</span>
			                )}
			            </Transition>

			             <Transition in={this.state.in} timeout={{ enter: 0, exit: 400}} mountOnEnter={true} unmountOnExit={true} appear>
			                {(state) => (
			                	<span className="background" onClick={this.close} style={{...transitionStyles2[state]}} /> 
			                )}
			            </Transition>


			            
		            </div>
		        )}
		   	 </Transition>	
		)
	}
}

export default withRouter(Overlay);