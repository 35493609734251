import React, { Component } from 'react'
import {withRouter, NavLink } from 'react-router-dom'
import {Transition} from 'react-transition-group'
import animateScrollTo from 'animated-scroll-to'
import { UrlTransform } from '../Helper'

const transitionStyles2 = {
	entering: { opacity: 0 },
	entered: { opacity:  0.4 },
	exiting: { opacity: 0 }
};


class Header extends Component {
	constructor(props) {
        super(props)

        this.state = {
            open: false
        }
    }

	toggleNavigation = () => {
        this.setState({
            open: !this.state.open
        })
    }

    hideNavigation = () => {
    	if(this.state.open) {
    		this.setState({
	            open: false
	        })
    	}
    }

    clickLogo = () => {
    	this.hideNavigation();

    	let scrollY = window.scrollY || window.pageYOffset

    	if(scrollY>1000 && !this.props.match.params.level1) {

    		const options = {
              // duration of the scroll per 1000px, default 500
              speed: 250,
             
              // minimum duration of the scroll
              minDuration: 800,
             
              // maximum duration of the scroll
              maxDuration: 2500,
              cancelOnUserAction: false
            };

            animateScrollTo(0, options);

    	}
    }

	render() {

		const isActiveSub = (path) => {
            if(this.props.location.pathname.indexOf(path)!==-1 || !this.props.match.params.level1) {
                return true;
            }
        }
		
		let navigation = this.props.contents.map((content, idx) => {
			if(content.navigation) {
				return <li key={idx}><NavLink onClick={this.hideNavigation} to={UrlTransform('/' + content.url, 'add')} activeClassName="selected" isActive={() => isActiveSub(content.url)} >{content.navigation}</NavLink></li>
			}
			return false
		})

		let classes = []
        if(this.state.open) classes.push('open')

		return (
			<div id="header" className={classes.join(' ')}>
				<div className="center main">
					{this.props.loaded &&
						<div>
							<NavLink onClick={this.clickLogo} to={UrlTransform('/', 'add')} className="logo">Duo Mattick Huth</NavLink>
							
							<div className="navigation">
								<span className="shadow" />
								<ul>
									{navigation}
								</ul>
							</div>
						
						
							<span className="menu" onClick={()=>this.toggleNavigation()}>
								<span />
								<span />
								<span /> 
							</span>
							
							<Transition in={this.state.open} timeout={{ enter: 0, exit: 400}} mountOnEnter={true} unmountOnExit={true} appear>
								{(state) => (
									<span className="background" onClick={this.hideNavigation} style={{...transitionStyles2[state]}} /> 
								)}
							</Transition>
						</div>
					}
				</div>
			</div>
		)
	}
}

export default withRouter(Header);