import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router-dom'

import moment from 'moment'
import 'moment/locale/de';
import { UrlTransform } from '../../Helper'

import Icon from '../Icon' 

class Dates extends Component {
	render() {	

		let data = this.props.data ? this.props.data : {dates: [] }

		//data = [];

		

		let hideTime = (this.props.position && this.props.match.params.level2 === 'archiv') ? true : false

		console.log(data)

		let content = data.dates.map((entry, idx) => {

			let date = moment(entry.date, "YYYY-MM-DD HH:mm:ss");
			let time = moment(entry.time, "HH:mm:ss");

			let meta = []

			if(entry.time && !hideTime) {
				meta.push(`${moment(time).format('HH')}:${moment(time).format('mm')} Uhr`)
			}

			/*
			if(entry.city) {
				meta.push(entry.city)	
			}
			*/

			if(entry.location) {
				meta.push(entry.location)	
			}

			let month = moment(date).locale('de').format('MMMM');

			if(this.props.style.width<=600) {
				month = month.slice(0, 3)
			} 

            return (
            	<li key={idx}>
            		<div className="table">
            			<div className="col">
            				<p className="date">
            					<span>{moment(date).format('dddd').slice(0, 2)}</span>
            					<span>{moment(date).format('DD')}</span>
            					<span>{month}</span>
            					<span>{moment(date).format('YYYY')}</span>
            				</p>
            			</div>
            			<div className="col">
            				<h3  dangerouslySetInnerHTML={{__html: entry.title}}></h3>
            				{(meta.length>0) &&
            					<span className="meta">{meta.join(', ')}</span>
            				}
            				<div className="rich" dangerouslySetInnerHTML={{__html: entry.text}} />
            			</div>
            		</div>
            	</li>
            )
        });

		let title

        if(this.props.position) {
        	title = this.props.pathName.indexOf('alle')>-1 ? 'alle Termine' : 'vergangene Termine';
        }

        let btn1 = 'alle Termine ansehen';

        if(this.props.style.width<=600) {
			btn1 = 'alle Termine';
		} 

		return (
			<div className="content dates">
				<div className="center">
					<div>
						{this.props.position &&
							<h2>{title}</h2>
						}
						{data.dates.length>0 ? (
							<ul>
							{content}
							</ul>
						): ( 
							<p className="empty">Zur Zeit keine aktuellen Termine.</p>
						)}
						
						{!this.props.position &&
							<div className="more clear">
								{(data.upcoming) &&
									<Link to={UrlTransform('/termine/alle', 'add')} className="all">{btn1}<Icon type="arrow" /></Link>
								}
								<Link to={UrlTransform('/termine/archiv', 'add')} className="archive">Archiv<Icon type="arrow" /></Link>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(Dates);