import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<Router>
    	<Route
            path="/:level1?/:level2?/:level3?"
            render={(props) => <App {...props} />}
        />
  	</Router>, 
  	document.getElementById('root')
);

serviceWorker.unregister();
