import React from 'react';
import Reflux from 'reflux'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'

import ContentStore from './stores/ContentStore'
import ContentStoreActions from './actions/ContentStoreActions'

import Header from './components/Header'
import Page from './components/page'
import Overlay from './components/content/overlay'
import { UrlTransform } from './Helper'

import animateScrollTo from 'animated-scroll-to';

import './App.css';

class App extends Reflux.Component {
    static contextTypes = {
        router: PropTypes.object
    }
    
    constructor(props) {
        super(props)

        this.stores = [ContentStore]

        const scale =  window.innerWidth /1200

        this.state = {
            styleOverlay: null,
            style: {
                scale: scale,
                 width: window.innerWidth,
                    height: window.innerHeight
            },
            loaded: false
        }  

        this.contents = [
            {
                name: 'duo',
                url: 'das-duo',
                ref: React.createRef(),
                navigation: 'Das Duo',
                y: null

            },
            {
                name: 'dates',
                url: 'termine',
                ref: React.createRef(),
                navigation: 'Termine',
                y: null
            },
            {
                name: 'records',
                url: 'aufnahmen',
                ref: React.createRef(),
                navigation: 'Aufnahmen',
                y: null
            },
            {
                name: 'demo',
                url: 'demo',
                ref: React.createRef(),
                navigation: 'Demo',
                y: null
            },
            {
                name: 'programs',
                url: 'programme',
                ref: React.createRef(),
                navigation: 'Programme',
                y: null
            },
            {
                name: 'downloads',
                url: 'downloads',
                ref: React.createRef(),
                navigation: 'Downloads',
                y: null
            },
            {
                name: 'contact',
                url: 'kontakt',
                ref: React.createRef(),
                navigation: 'Kontakt',
                y: null
            },
            {
                name: 'end',
                url: '',
                ref: React.createRef(),
                y: null
            }
        ]
    }

    componentDidMount(){
        if(this.props.match.params.level1) {
            let start = this.contents.findIndex(content => content.name === this.props.match.params.level1)
            if(start===-1) {
                this.props.history.replace('/')
            } 
        }

        window.addEventListener('resize', this.setSizes)
        window.addEventListener('scroll', this.scroll)

        //this.setSizes();

        ContentStoreActions.getContent(UrlTransform('/', 'add'))
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.setSizes)
        window.removeEventListener('scroll', this.scroll)
    }

    setSizes = () => {
        
        //setTimeout(() => {
            let scrollY = window.scrollY || window.pageYOffset;

            for(let key in this.contents) {
                let content = this.contents[key];
                let rect = content.ref.current.getBoundingClientRect()
                
                content.y = content.name==='duo' ? (window.innerHeight/2)+10 : rect.top + scrollY

            }

            const scale =  window.innerWidth /1200

            this.setState({
                style: {
                    scale: scale,
                    width: window.innerWidth,
                    height: window.innerHeight,

                }
            })
        /*
        }
        , 50);  
        */
    }

    scroll = () => {
        clearTimeout(this.timeout);

        if(this.state.overlay) {
            return 
        }
        
        let scrollY = window.scrollY || window.pageYOffset,
        current;

        for(let key in this.contents) {
            let content = this.contents[key];
            let y = content.y

            if(y<scrollY+window.innerHeight*0.25) {
                current = content.url;
            }
        }

        if(scrollY<window.innerHeight/2) {
            current = ''
        }

        if(this.lastScroll !== current && !this.props.match.params.level2) {
            this.skipAutoScroll = true
            this.lastScroll = current
            this.props.history.push(  UrlTransform('/' + current, 'add') );
        }
        
    }   

    componentDidUpdate(prevProps, prevState) {
        
        if(!this.state.loaded && this.state.content[encodeURIComponent(UrlTransform('/'))]) {
            this.setState({loaded: true});
           
        } else { 

            if(this.state.loaded && !prevState.loaded) {
                
                this.setSizes();

                let anchor = this.props.match.params.level1 ? this.props.match.params.level1 : null

                if(anchor) {

                    let content = this.contents.filter(entry => entry.url === anchor)[0];

                    let y = content.y + 2

                    window.scroll(
                       0, y
                    )

                }  

                setTimeout(() => {
                    this.setSizes();
                }
                , 250); 

            }


            if(this.props.match.params.level2 && !this.state.overlay) {


                this.scrollTop = window.scrollY || window.pageYOffset;
                //alert(this.scrollTop);

                let styleOverlay = {
                    react: {
                        position: 'fixed',
                        overflow: 'hidden',
                        height: '100%'
                    },
                    page: { 
                        marginTop: `-${window.scrollY || window.pageYOffset}px`
                    }
                }

                this.setState({styleOverlay: styleOverlay, overlay: true});
                

                this.overlayOpen = true;


            } else if(!this.props.match.params.level2 && this.state.styleOverlay) {

                this.setState({styleOverlay: null, overlay: false, minHeight: null});
                  
            }

            if(!this.state.overlay && prevState.overlay) {

              
                this.overlayOpen = false;
                this.setSizes()
         
                window.scrollTo(0, this.scrollTop)
            }

        }
        
    }

    
    componentWillUpdate(nextProps, nextState) {

        if(this.state.overlay || nextProps.match.params.level2) {
            return 
        }

        let anchorLast = this.props.match.params.level1 ? this.props.match.params.level1 : null
        let anchor = nextProps.match.params.level1 ? nextProps.match.params.level1 : null

        if(anchor !== anchorLast && !this.skipAutoScroll) {
            let content = this.contents.filter(entry => entry.url === anchor)[0];


            let y = content ? content.y : 0

            y+=2;
            /*
            window.scrollTo({
                top: y, 
                behavior: "smooth"
            })
            */

            const options = {
              // duration of the scroll per 1000px, default 500
              speed: 250,
             
              // minimum duration of the scroll
              minDuration: 800,
             
              // maximum duration of the scroll
              maxDuration: 2500,
              cancelOnUserAction: false
            };

            animateScrollTo(y, options);
        }

        this.first = true
        this.skipAutoScroll = false
    }

    setMinHeight = (minHeight) => {
        if(minHeight !== this.state.minHeight) {
            this.setState({minHeight: minHeight});
        }
    }

    render() {
        let data = this.state.content[encodeURIComponent(UrlTransform('/'))];

        let content = []

        let showLogo = false

        if(!this.props.match.params.level1 || this.props.match.params.level1 === 'das-duo' || this.props.match.params.level1 === 'kontakt') {
            showLogo = true;
        }

        if(this.state.loaded) {
            content = this.contents.map((content, idx) => { 
                
                let contentData = data ? data[content.name] : null

                if(content.name==='end') {
                    contentData = data['duo']
                }

                return  <Page 
                            key={idx}
                            content={content.name} node={content.ref}
                            data={contentData}
                            style={this.state.style}
                            showLogo={showLogo}
                        /> 
            });
        } else {
            content.push(
                <Page 
                    key={0}
                    content="loading"
                    style={this.state.style}
                    showLogo={showLogo}
                />
            )
        }

        if(this.props.match.params.level2) {
            this.url = this.props.location.pathname;
            console.log( this.url);
        }

        let minHeight = this.state.minHeight ? this.state.minHeight : this.state.style.height

        return (
            <div id="react">
                <Header contents={this.contents} loaded={this.state.loaded} />
                <div id="container" style={{minHeight: `${minHeight}px`}}>
                    <div id="content" style={this.state.styleOverlay ? this.state.styleOverlay.react : null}>
                        <div style={this.state.styleOverlay ? this.state.styleOverlay.page : null}>
                            {content}
                        </div>
                    </div>
                    {this.state.loaded &&
                        <Overlay
                            in={!!this.props.match.params.level2}
                            url={this.url}
                            style={this.state.style}
                            setMinHeight={(minHeight) => this.setMinHeight(minHeight)}
                        />
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(App)
