import React, { Component } from 'react'
import Icon from '../Icon' 

class Contact extends Component {
	constructor(props) {
 		super(props)

 		this.imprint = React.createRef();

        this.state = {
            open: false,
        } 
 	}

 	toggleImprint = () => {
 		this.setState({
            open: !this.state.open
        })
 	}

	render() {

		let data = this.props.data

		let imprintHeight = 0

		if(this.state.open) {
			let rect = this.imprint.current.getBoundingClientRect()
			imprintHeight = rect.height;

		}	

		return (
			<div className="content contact">
				<div className="center main">
					<div>
						<div className="rich" dangerouslySetInnerHTML={{__html: data ? data.text1 : null}} />
						<div className={'imprint' + (this.state.open ? ' open' : '')}>
							<h3 onClick={() => this.toggleImprint()}>Impressum & Datenschutz<Icon type="arrow" /></h3>
							<div className="container" style={{height: `${imprintHeight}px`}}>
								<div className="rich" ref={this.imprint} dangerouslySetInnerHTML={{__html: data ? data.text2 : null}} />
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Contact;