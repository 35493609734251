import React, { Component } from 'react'
import { Transition } from 'react-transition-group'

const transitionStyles = {
    entering: { opacity: 0  },
    entered: { opacity: 1 },
    exiting: { opacity: 0  }
};

class Demo extends Component {
	constructor(props) {
 		super(props)

        this.state = {
            currentImage: 0,
            direction: 'next',
            round :0,
            showText: true
        }; 
 	}

 	showSlide = (direction) => {
        let data = this.props.data,
    	currentImage = this.state.currentImage,
        round = this.state.round

        switch(direction) {
        	case 'previous':

        		currentImage--

        		if(currentImage<0) {
        			currentImage = data.length-1
                    round--;
        		}

        		break;

        	case 'next':
            default:

        		currentImage++

        		if(currentImage>data.length-1) {
        			currentImage = 0
                    round++
        		}

        		break;
        }


        this.first = false

        this.setState({
            lastImage: this.state.currentImage,	
 			currentImage,
            direction,
            round,
            showText: false,
            playing: null
 		});

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            
            this.setState({
                showText: true
            });

        }
        , 200);  
    }

 	handleTouchStart = (e) => {
        this.touchXstart = e.touches[0].clientX;
    }

    handleTouchMove = (e) => {
        let touchX =  e.touches[0].clientX - this.touchXstart;
        
        if(Math.abs(touchX)>10) {
            this.setState({
                touching: true,
                touchX: touchX 
            });
        }
    }

    handleTouchEnd = (e) => {
        let touchX =  e.changedTouches[0].clientX - this.touchXstart;
        
        if(this.state.touching) {

            if(Math.abs(touchX)>50) {

                this.setState({
                    touching: false
                });

                if(this.state.touchX > 0) {
                    this.showSlide('previous')
                } else {
                    this.showSlide('next')
                }
            } else {

                this.setState({
                    touching: false,
                    touchX: 0
                });

            }

        }
    }

    playVideo = (number) => {
    	this.setState({
            playing: number
        });
    }  


	render() {	

		let data = this.props.data ? this.props.data : []

		let content = [],
		text = [],
		navigation = []

		if(data.length) {
            for(let i = -1; i<= data.length; i++) {

                let index = i === -1 ? data.length-1 : i===data.length ? 0 : i

                let image = data[index]     

                let backgroundImage = '';
                
               	if((this.state.lastImage===i && !this.state.showText) || (this.state.currentImage===i && this.state.showText)) {
                    text.push(
                        <Transition key={i} in={this.state.currentImage===i && this.state.showText} timeout={{ enter: 0, exit: 200}} mountOnEnter={true} unmountOnExit={true} appear={this.first ? false : true}>
                            {(state) => (
                                <p className="text" style={{...transitionStyles[state]}}  dangerouslySetInnerHTML={{__html: image.text}}></p>
                         )}
                        </Transition>   
                    )
                }

                backgroundImage = `url(https://i3.ytimg.com/vi/${image.youtube_id}/maxresdefault.jpg)`

                let left = ((this.state.round*data.length) + i) * 100;

                content.push(<li style={{backgroundImage: backgroundImage, left: `${left}%`}} key={i} onClick={() => this.playVideo(i)}>
                	{this.state.playing === i ? (
				        <iframe title={image.text} width="100%" height="100%" src={`https://www.youtube-nocookie.com/embed/${image.youtube_id}?autoplay=1`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				    ) : (
				        <span className="play"></span>
				    )}
                </li>)

                if(i>=0 && i< data.length) {
                	navigation.push(<li key={i} className={this.state.currentImage===i ? 'selected' : ''}><span /></li>)
                }
            }
        }

        let translateX = ((this.state.round*data.length) + this.state.currentImage) * -100;

        if(this.state.touching && this.ref) {
            translateX += (this.state.touchX/this.ref.clientWidth)*100; 
        }

        let classNames = []
        if(this.state.touching) classNames.push('touching')	

		return (
			<div className="content demo">
				<div className="center">
					<div>
						<div className={'slider ' + classNames.join(' ')} ref={ref => {this.ref = ref}}>
							<div className="media clear">
			                    <div>
			                        {data.length > 1 &&
			                            <ul style={{transform: `translateX(${translateX}%)` }} 
			                            	className={this.state.touching ? 'touching' : null}
			                            	onTouchStart={this.handleTouchStart}
			                            	onTouchMove={this.handleTouchMove}
			                            	onTouchEnd={this.handleTouchEnd}
			                            >
			            					{content}
			            				</ul>
			                        }
			                        {data.length <= 1 &&
			                            <ul style={{transform: `translateX(${translateX}%)` }} 
			                                className={this.state.touching ? 'touching' : null}
			                            >
			                                {content}
			                            </ul>
			                        }
			                    </div>
			    				{data.length>1 &&
			    					<span className="button previous" onClick={()=>this.showSlide('previous')}></span>   					
			                	}
			                	{data.length>1 &&
			                		<span className="button next" onClick={()=>this.showSlide('next')}></span>
			                	}
			                </div>
			                <div className="text">
			                	{text}
			                </div>
			                <div className="navigation">
			                	<ul>
			                		{navigation}
			                	</ul>
                                {data.length>1 &&
                                    <span className="button previous" onClick={()=>this.showSlide('previous')}></span>                      
                                }
                                {data.length>1 &&
                                    <span className="button next" onClick={()=>this.showSlide('next')}></span>
                                }
			                </div>
			            </div>
			        </div>
			    </div>
			</div>
		)
	}
}

export default Demo;