import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {Link} from 'react-router-dom'

import { UrlTransform } from '../../Helper'

class Programs extends Component {
	 constructor(props) {
        super(props)

        this.state = {
            active: null
        }  
    }

	onClick = (e, url) => {
        this.setState({
			active: url
		})
	}

	

	componentDidMount() {
		document.getElementById("react").addEventListener("mouseover", () => {
			if(!this.props.match.params.level2) {
				this.setState({
					active: null
				})
			}
		});
	} 

	render() {	
		const isActive = (path) => {
            if(this.state.active && this.state.active.indexOf(path)!==-1) {
                return 'selected';
            }
        }

		let data = this.props.data ? this.props.data : []

		let content = data.map((entry, idx) => { 

			let url = UrlTransform( entry.url, 'add');

            return (
            	<li key={idx} className={isActive(url)}>
            		<Link to={url} className="area" onClick={(e) => this.onClick(e, url)}></Link>
            		<h3 dangerouslySetInnerHTML={{__html: entry.title}}></h3>
            		<h4 dangerouslySetInnerHTML={{__html: entry.subTitle}}></h4>
            	</li>
            )
        });

		return (
			<div className="content programs">
				<ul>
					{content}
				</ul>
			</div>
		)
	}
}

export default withRouter(Programs);