import React, { Component } from 'react'
import AudioPlayer from './audioplayer'

class Records extends Component {
	constructor(props) {
		super(props)

		this.state = {
			playingID: null
		}
	}

	playAudio = (id) => {
		console.log('set id: ' + id);
		this.setState({ playingID: id });
	}

	render() {

		let data = this.props.data ? this.props.data : []

		let content = data.map((entry, idx) => {

			let tracks = entry.tracks.map((track, idx) => {
				return <AudioPlayer
					key={idx}
					data={track}
					onPlay={(id) => this.playAudio(id)}
					playingID={this.state.playingID}
				/>
			});

			let title = entry.title
			if(entry.subTitle) title += `<br /><span>${entry.subTitle}</span>` 

			return (
				<li key={idx}>
					<div className="table">
						<div className="col">
							<h3 dangerouslySetInnerHTML={{ __html: title }} />
							<div className="image">
								<span style={{ paddingBottom: `${(entry.height / entry.width) * 100}%`, backgroundImage: `url(${entry.image})` }} />
							</div>
						</div>
						<div className="col">
							<h3 dangerouslySetInnerHTML={{ __html: title }} />
							<span className="meta" dangerouslySetInnerHTML={{ __html: entry.label }} />
							<div className="rich" dangerouslySetInnerHTML={{ __html: entry.text }} />
							{tracks}
						</div>
					</div>
				</li>
			)
		});

		return (
			<div className="content records">
				<div className="center">
					<div>
						<ul>
							{content}
						</ul>
					</div>
				</div>
			</div>
		)
	}
}

export default Records;