import React, { Component } from 'react'

import Loading from '../Loading'
import Duo from '../content/Duo'
import Dates from '../content/Dates'
import Records from '../content/Records'
import Demo from '../content/Demo'
import Programs from '../content/Programs'
import Downloads from '../content/Downloads'
import Contact from '../content/Contact'

const components = {
	duo: Duo,
    dates: Dates,
    records: Records,
    demo: Demo,
    programs: Programs,
    downloads: Downloads,
    contact: Contact
}

class Page extends Component {
	constructor(props) {
        super(props)

        this.state = {
            opacity: 0.85
        }

        this.ref = React.createRef()

        if(this.props.content==='duo' || this.props.content==='loading') {
            this.contentLogo = true;
        } 

        
        if(this.props.content==='records' || this.props.content==='downloads') {
            this.contentShadow = true;
        }
        

        /*
        if(this.props.content === 'loading') {
            this.image = 'http://duo-mattick-huth.de/preview/wordpress/wp-content/uploads/2019/03/0B1A0004_08a-1-2500x1667.jpg'
        }
        */


    }

	componentWillUpdate(nextProps, nextState) {
		this.image = nextProps.data ? nextProps.data.image : null
    }

	componentDidMount(){
        if(this.contentShadow) {
            window.addEventListener('resize', this.resize)
            this.resize();
        }

 		if(this.contentLogo || this.contentShadow) {
        	window.addEventListener('scroll', this.scroll)
            window.addEventListener('resize', this.scroll)
    	}
    }

    resize = () => {
        let scrollY = window.scrollY || window.pageYOffset;
        let rect = this.ref.current.getBoundingClientRect()
   
        this.y = rect.top + scrollY;
        this.area = this.y + rect.height;
    }

    scroll = () => {

       	let scrollY = window.scrollY || window.pageYOffset

         //console.log(scrollY + '>' + this.contentShadow)

        if(this.contentLogo) {

           	let documentHeight = document.documentElement.scrollHeight
           	let windowHeight = window.innerHeight/2;

            let opacity = 0;

           	if(scrollY<windowHeight) {
           		opacity = (windowHeight-scrollY)/windowHeight
           	} else if(scrollY>documentHeight-(3*windowHeight)) {
           		opacity = (scrollY-(documentHeight-(3*windowHeight)))/windowHeight
           	}

            opacity*=0.85;
            /*
            if(opacity>0.85) {
                opacity = 0.85;
            }
            */

           	//console.log('>>' + (documentHeight-(3*windowHeight)))
           	//console.log('>>' + scrollY)

           	if(opacity !== this.state.opacity) {
           		this.setState({
                    opacity
                })
           	}

        }

        //console.log(this.y)

        if(this.contentShadow) {

            let shadowFixed = false
            let shadowHide = false

            if(scrollY>this.y) {
               shadowFixed = true;
            } 

            if(scrollY>this.area) {
               shadowHide = true;
            } 

            if(shadowFixed !== this.state.shadowFixed) {
                this.setState({
                    shadowFixed
                })
            }

            if(shadowHide !== this.state.shadowHide) {
                this.setState({
                    shadowHide
                })
            }
        }

    }   

	shouldComponentUpdate(nextProps, nextState) {
  		
        if(this.props.style !== nextProps.style || this.props.showLogo !== nextProps.showLogo || this.state.opacity !== nextState.opacity || this.state.shadowFixed !== nextState.shadowFixed || this.state.shadowHide !== nextState.shadowHide) {
  			return true
  		}

  		if(this.props.data) {
  			return false
  		} 
  		
  		return true
	}

	render() {
		let data = this.props.data;

		let content;

		if(components[this.props.content]) {
			const Component = components[this.props.content]
            content = <Component data={data && data.content ? data.content : null} style={this.props.style} />
		}

        let h1

        if(this.contentLogo && this.props.showLogo) {

            if(this.props.style.width > 800) {
                h1 = <h1 style={{opacity: this.state.opacity, fontSize: `${this.props.style.scale*168.2}px`}}>Mattick<span style={{marginLeft: `${this.props.style.scale*-15}px`, marginTop: `${this.props.style.scale*-18}px`}}>Flöte & Piano</span>Huth</h1> 
            } else {
                h1 = <h1 className="small" style={{opacity: this.state.opacity, lineHeight: `${this.props.style.scale*288.2}px`, fontSize: `${this.props.style.scale*288.2}px`}}>Mattick<span style={{marginLeft: `${this.props.style.scale*-50}px`, marginTop: `${this.props.style.scale*12}px`, marginBottom: `${this.props.style.scale*12}px`}}>Flöte & Piano</span>Huth</h1> 
            }
        }


		return (
			<div className={'page ' + this.props.content} ref={this.props.node}>
				<div className="bg" style={{backgroundImage: `url(${this.image})` }} />
				{this.contentLogo &&
					h1
                }
                <Loading />
				{(this.props.content !== 'end' && this.props.content !== 'loading') &&
				<div className="content" 
                    ref={this.ref}
                >
					{(this.contentShadow && !this.state.shadowHide) &&
                        <span className={'shadow' + (this.state.shadowFixed ? ' fixed' : '')} />
                    }
                    {data && data.copyright &&
						<p className="copyright">{data.copyright}</p>
                    }
					
					<h2>{data &&
						data.title}</h2>
					{content}

				</div>
				}
            </div>
		)
	}
}

export default Page;