import React, { Component } from 'react'

import { FormatBytes } from '../../Helper'

class Downloads extends Component {
	render() {	

		let documents = this.props.data && this.props.data.documents ? this.props.data.documents : [],
		photos = this.props.data && this.props.data.photos ? this.props.data.photos : [],
		contentDocuments = [],
		contentPhotos = []
		

		contentDocuments = documents.map((entry, idx) => {
			var extension = entry.file.split('.').pop().toUpperCase();

			return <li key={idx}><a href={entry.file} className={`file ${extension.toLowerCase()}`} download><span>{entry.description}</span> <span className="meta">({extension}, {FormatBytes(entry.filesize)})</span></a></li>
		});

		for(var i=0; i<photos.length; i++) {
			let entry = photos[i]

			let filename = entry.file.split('\\').pop().split('/').pop();

			contentPhotos.push(
				<li key={i}>
					<a className="area" href={entry.file} download={filename}></a>
            		<div className="image" style={{backgroundImage: `url(${entry.image})` }} />
            		<p>{entry.copyright}</p>
            		<span className="meta">{entry.filename}<br />
            		{entry.width} x {entry.height}px<br />
            		{FormatBytes(entry.filesize)}</span>
            	</li>
			)

			if(i<photos.length-1) {
				if((i+1)%3===0) {
					contentPhotos.push(<li className="separator col3" key={`sep3${i}`}/>)
				}

				if((i+1)%2===0) {
					contentPhotos.push(<li className="separator col2" key={`sep2${i}`}/>)
				}
			}
		}

		return (
			<div className="content downloads">
				<div className="center">
					<div>
						<div className="documents">
							<ul>
								{contentDocuments}
							</ul>
						</div>
						<div className="photos clear">
							<ul>
								{contentPhotos}
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Downloads;